import React from 'react';
import '../Styles/customer.css';

const Customer = (props) => {

    const logos = [
        {logo: './assets/logos/1.png',   name: 'عكاظ'},
        {logo: './assets/logos/2.png',   name: 'ملاذ للتأمين'},
        {logo: './assets/logos/3.png',   name: ''},
        {logo: './assets/logos/4.png',   name: ''},
        {logo: './assets/logos/5.png',   name: ''},
        {logo: './assets/logos/6.png',   name: 'التعاونية'},
        {logo: './assets/logos/7.png',   name: 'تأجير للتمويل'},
        {logo: './assets/logos/8.png',   name: 'ولاء'},
        {logo: './assets/logos/9.png',   name: ''},
        {logo: './assets/logos/10.png',  name: 'STC'},

        {logo: './assets/logos/11.png',  name: 'وزارة البيئة والمياه والزراعة'},
        {logo: './assets/logos/12.png',  name: 'نفط'},
        {logo: './assets/logos/13.png',  name: 'Schindler'},
        {logo: './assets/logos/14.png',  name: 'TRANE'},
        {logo: './assets/logos/15.png',  name: 'DHL'},
        {logo: './assets/logos/16.png',  name: 'رؤية 2030'},
        {logo: './assets/logos/17.png',  name: 'عناية السعودية'},
        {logo: './assets/logos/18.png',  name: 'دله للتجارة'},
        {logo: './assets/logos/19.png',  name: 'سدكو'},
        {logo: './assets/logos/20.png',  name: 'ليبارا'},

        {logo: './assets/logos/21.png',  name: 'بوبا'},
        {logo: './assets/logos/22.png',  name: 'السعودية'},
        {logo: './assets/logos/23.png',  name: 'دله البركة'},
        {logo: './assets/logos/24.png',  name: 'مختبرات تبياناالطبية'},
        {logo: './assets/logos/25.png',  name: 'ارامكس'},
    ];

    const english = props.english;
    const title = ['عملاءنا', 'Our Clients'];

    return (
        <div className='section-8' id='عملاءنا'>
            
            <h1 className='title-8'>{english == true ? title[1] : title[0]}</h1>

            <div className='container-8'>
                {logos.map((lo) => (
                    <img 
                        src={lo.logo} 
                        alt={lo.name} 
                        className='logo'
                    />
                ))}
            </div>

        </div>
        
    );
}
 
export default Customer;