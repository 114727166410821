import React from 'react';
import '../Styles/home.css';

const Home = (props) => {
    const english = props.english;
    const title = ['أفضل كادر من ', 'The Best Staff From '];
    const company = ['المصادر', 'Source'];
    return (
            <div className='section-1' id='الرئيسية' style={{
                backgroundImage: 'url(./assets/images/home_bg.png)'
            }}>
                <div className='container-1-3'>
                    <h1>{english == true ? title[1] : title[0]}
                        <span style={{color: '#aa8124'}}>{english == true ? company[1] : company[0]}</span>
                    </h1>
                    <img id='sh1' src="./assets/shapes/Shape.png" alt="" />
                    <img id='sh2' src="./assets/shapes/Shape1.png" alt="" />
                    <img id='sh3' src="./assets/shapes/Shape2.png" alt="" />
                    <img id='sh4' src="./assets/shapes/Shape3.png" alt="" />
                    <img id='sh5' src="./assets/shapes/Shape5.png" alt="" />
                    <img id='ch1' src="./assets/shapes/1.png" alt="" />
                    <img id='ch2' src="./assets/shapes/3.png" alt="" />
                    <img id='ch3' src="./assets/shapes/5.png" alt="" />
                    <img id='ch4' src="./assets/shapes/6.png" alt="" />
                    <img id='ch5' src="./assets/shapes/8.png" alt="" />
                    <img id='ch6' src="./assets/shapes/9.png" alt="" />
                </div>
            </div>
    );
}
 
export default Home;