import React from 'react';
import '../Styles/goals.css';

const Goals = (props) => {

    const goals = [
        {number: '1', textAR: 'تخفيض تكاليف التشغيل على العملاء', textEN: 'Reduce operating costs for customers'},
        {number: '2', textAR: 'الاهتمام بكوادرنا ليكونوا عناصر أساسية في ريادة عملائنا في نطاقات عملهم', textEN: 'Attention to our staff to be essential elements in the leadership of our customers in their fields of work'},
        {number: '3', textAR: 'التطوير المستمر لحلول خدمات الأعمال', textEN: 'Continuous development of business services solutions'},
        {number: '4', textAR: 'التوسع الإقليمي باستقطاب وتصدير الكوادر من وإلى الخارج', textEN: 'Regional expansion by attracting and exporting staff from and abroad'},
    ];

    const english = props.english;
    const title = ['أهدافنا', 'Our Goals'];

    const screenWidth  = () => {
        if(window.innerWidth <= 800){
            return true;
        }else{
            return false;
        }
    };

    return (
        <div className='section-5' id='الهدف'>

            <div className='shad-1'></div>
            <div className='shad-2'></div>

            <h1 className='title-5'>{english == true ? title[1] : title[0]}</h1>

            <div className='container-5-1'>  

                {goals.map((goal) => (
                    <div className='container-5-2' style={{width: screenWidth() ? '100%' : 'calc(120px + 12vw)'}}>
                        <h1 style={{left: english ? '0%' : 'auto'}}>{goal.number}</h1>
                        <div className='container-5-3'>
                            <p style={{textAlign: english ? 'end' : 'start'}}>
                                {english== true ? goal.textEN : goal.textAR}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
 
export default Goals;