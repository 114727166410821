import React from 'react';
import '../Styles/reasons.css';

const Reasons = (props) => {
    const english = props.english;

    const title = [
        'لماذا المصادر؟',
        '?Why Sources',

        'الوقت',
        'Time',

        'الحقوق',
        'Rights',

        'العمل الجماعي',
        'Teamwork',

        'الشفافية',
        'Transparency',

        'شهادة الآيزو',
        'ISO certification'
    ];

    const text = [
        'مما لا شك فيه بأننا لسنا الوحيدين في سوق العمل, إلا أننا نعتبر شركاء في بناء اقتصاد هذا الوطن, مما جعلنا نحصل على ما يلي',
        'Undoubtedly, we are not the only ones in the labor market, but we are considered partners in building the economy of this country, which made us obtain the following',

        'أحد الأمور التي لها أهمية قصوى في عمل (المصادر) فهو عامل لا يعوض أبداً لذى بنيت الاستراتيجية العامة للعمل فيها بإعطائه نصيباً كبيراً من الاهتمام فبدون استثماره كيفما ينبغي لا يمكن أن نحقق الأهداف',
        'One of the things that are of paramount importance in the work of (sources), it is a factor that never compensates for the one who built the general strategy to work in it by giving it a large share of attention. Without investing it as it should, we cannot achieve the goals',

        'في المصادر العالية نعي تماماً بأن للعميل حقوقاً يجب أن ينالها كاملة دون نقصان, وللمتعاقد كذلك, وقد وضعنا نصب أعيننا إعطاء كل ذي حقٍ حقه, لأن الأسس المتينة تتجسد في الصدق والنزاهة واحترام الذات, لتستمر عجلة الاقتصاد في النمو والازدهار',
        'In the higher sources, we are fully aware that the customer has rights that he must have in full without any deficiency, and the contractor has rights as well',

        'وجدت المصادر العالية من أجل مساعدة المجتمع عبر ممارسة الأنشطة التي من شأنها تحسين مستوى الحياة لدى الفرد, والابتعاد عن كل ما قد يضره',
        'The high sources were found in order to help the community through the practice of activities that would improve the standard of life for the individual, and to stay away from everything that might harm him',

        'نؤمن في المصادر العالية تماماً بأن المصداقية مع العميل. هي الأساس المتين الذي تبقى عليه العلاقات لأمدٍ طويل, لذا نحرص دائما على إعطاء المعلومات الصحيحة للعميل',
        'We believe in high sources completely that the credibility with the customer. It is the solid foundation on which relationships remain for a long time, so we are always keen to give the correct information to the client',

        'حصلت شركة المصادر على شهادة (ISO) لإدارة الجودة في العمل',
        'Source Company has obtained an (ISO) certificate for quality management at work',
    ];

    const screenWidth  = () => {
        if(window.innerWidth <= 800){
            return true;
        }else{
            return false;
        }
    };

    return (
        <div className='section-4' id='المصادر'>
            <div className='sdw' id='first'></div>
            <div className='sdw' id='second'></div>
            <div className='sdw' id='third'></div>
            <div className='sdw' id='forth'></div>

            <div className='container-4-1' style={{alignSelf: english ? 'end' : 'flex-start'}}>
                <h1 style={{marginRight: english ? 'auto' : '0', alignSelf: english ? 'end' : 'start'}}>
                    {english == true ? title[1]: title[0]}
                </h1>
                <p style={{textAlign: english ? 'end' : 'justify'}}>
                    {english == true ? text[1]: text[0]}
                </p>
            </div>

            {screenWidth() ? 
                <div className='container-4r' style={{flexDirection: screenWidth() ? 'column' : 'row'}}>
                    <div className='img-comp' style={{margin: 'auto'}}>
                        <div className='sq1' style={{zIndex: '0'}}></div>
                        <div className='bd-sq1' style={{zIndex: '0'}}></div>
                        <img src="./assets/images/time.jpg" alt="" style={{width: screenWidth() ? '100%' : '90%', position: 'relative'}}/>
                    </div>

                    <div className='text-part' style={{width: screenWidth() ? '100%' : '50%'}}>
                        <h1 style={{alignSelf: english ? 'flex-end' : 'flex-start'}}>
                            {english == true ? title[3]: title[2]}
                        </h1>
                        <p style={{textAlign: english ? 'end' : 'justify'}}>
                            {english == true ? text[3]: text[2]}
                        </p>
                    </div>
                </div>
            :
                <div className='container-4r' style={{flexDirection: screenWidth() ? 'column' : 'row'}}>
                    <div className='text-part' style={{width: screenWidth() ? '100%' : '50%'}}>
                        <img src="./assets/icon.png" alt="" style={{width: '10vw', height: '10vw'}}/>
                        <h1 style={{alignSelf: english ? 'flex-end' : 'flex-start'}}>
                            {english == true ? title[3]: title[2]}
                        </h1>
                        <p style={{textAlign: english ? 'end' : 'justify'}}>
                            {english == true ? text[3]: text[2]}
                        </p>
                    </div>

                    <div className='img-comp' style={{marginLeft: '0'}}>
                        <div className='sq1' style={{zIndex: '0'}}></div>
                        <div className='bd-sq1' style={{zIndex: '0'}}></div>
                        <img src="./assets/images/time.jpg" alt="" style={{width: screenWidth() ? '100%' : '90%', position: 'relative'}}/>
                    </div>
                </div>
            }

            <div className='container-4l' style={{flexDirection: screenWidth() ? 'column' : 'row'}}>

                <div className='img-comp' style={{marginLeft: screenWidth() ? 'auto' : '0', margin: 'auto'}}>
                    <div className='sq1' style={{zIndex: '0', right: 'auto', left: '0'}}></div>
                    <div className='bd-sq1' style={{zIndex: '0', right:'0', left: 'auto'}}></div>
                    <img src="./assets/images/rights.jpg" alt="" style={{width: screenWidth() ? '100%' : '90%', position: 'relative'}}/>
                </div>

                <div className='text-part' style={{width: screenWidth() ? '100%' : '50%'}}>
                    {screenWidth() ? null : <img src="./assets/icon.png" alt="" style={{width: '10vw', height: '10vw'}}/>}
                    <h1 style={{alignSelf: english ? 'flex-end' : 'flex-start'}}>
                        {english == true ? title[5]: title[4]}
                    </h1>
                    <p style={{textAlign: english ? 'end' : 'justify'}}>
                        {english == true ? text[5]: text[4]}
                    </p>
                </div>

            </div>

            {screenWidth() ? 
                <div className='container-4r' style={{flexDirection: screenWidth() ? 'column' : 'row'}}>
                    <div className='img-comp' style={{margin: 'auto'}}>
                        <div className='sq1' style={{zIndex: '0'}}></div>
                        <div className='bd-sq1' style={{zIndex: '0'}}></div>
                        <img src="./assets/images/teamwork.jpg" alt="" style={{width: screenWidth() ? '100%' : '90%', position: 'relative'}}/>
                    </div>

                    <div className='text-part' style={{width: screenWidth() ? '100%' : '50%'}}>
                        <h1 style={{alignSelf: english ? 'flex-end' : 'flex-start'}}>
                            {english == true ? title[7]: title[6]}
                        </h1>
                        <p style={{textAlign: english ? 'end' : 'justify'}}>
                            {english == true ? text[7]: text[6]}
                        </p>
                    </div>
                </div>
            :
                <div className='container-4r' style={{flexDirection: screenWidth() ? 'column' : 'row'}}>
                    <div className='text-part' style={{width: screenWidth() ? '100%' : '50%'}}>
                        <img src="./assets/icon.png" alt="" style={{width: '10vw', height: '10vw'}}/>
                        <h1 style={{alignSelf: english ? 'flex-end' : 'flex-start'}}>
                            {english == true ? title[7]: title[6]}
                        </h1>
                        <p style={{textAlign: english ? 'end' : 'justify'}}>
                            {english == true ? text[7]: text[6]}
                        </p>
                    </div>

                    <div className='img-comp' style={{marginLeft: '0'}}>
                        <div className='sq1' style={{zIndex: '0'}}></div>
                        <div className='bd-sq1' style={{zIndex: '0'}}></div>
                        <img src="./assets/images/teamwork.jpg" alt="" style={{width: screenWidth() ? '100%' : '90%', position: 'relative'}}/>
                    </div>
                </div>
            }

            <div className='container-4l' style={{flexDirection: screenWidth() ? 'column' : 'row'}}>

                <div className='img-comp' style={{marginLeft: screenWidth() ? 'auto' : '0', margin: 'auto'}}>
                    <div className='sq1' style={{zIndex: '0', right: 'auto', left: '0'}}></div>
                    <div className='bd-sq1' style={{zIndex: '0', right:'0', left: 'auto'}}></div>
                    <img src="./assets/images/trans.jpg" alt="" style={{width: screenWidth() ? '100%' : '90%', position: 'relative'}}/>
                </div>

                <div className='text-part' style={{width: screenWidth() ? '100%' : '50%'}}>
                    {screenWidth() ? null : <img src="./assets/icon.png" alt="" style={{width: '10vw', height: '10vw'}}/>}
                    <h1 style={{alignSelf: english ? 'flex-end' : 'flex-start'}}>
                        {english == true ? title[9]: title[8]}
                    </h1>
                    <p style={{textAlign: english ? 'end' : 'justify'}}>
                        {english == true ? text[9]: text[8]}
                    </p>
                </div>

            </div>

            <div className='container-4-2'>
                <img src="./assets/logos/ISO.png" alt="" />
                <h1>
                    {english == true ? title[11]: title[10]}
                </h1>
                <p>
                    {english == true ? text[11]: text[10]}
                </p>
            </div>

        </div>
        
    );
}
 
export default Reasons;