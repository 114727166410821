import React from 'react';
import '../Styles/visionMessage.css';

const VisionMessage = (props) => {

    const english = props.english;
    const title = ['رؤيتنا', 'Our Vision', 'رسالتنا', 'Our Message'];

    const text = [
        'أن نصبح الشركة الرائدة في مجال حلول الأعمال, بما يفوق تطلعات عملاءنا, من خلال خدمات ذات جودة واحترافية منقطعة النظير',
        'To become the leading company in the field of business solutions, exceeding the aspirations of our customers, through unparalleled quality and professional services',
        'تسعى المصادر العالية دائماً إلى إعطاء الأولوية للكادر الأفضل في شتى المجالات, لبناء حاضر شركائنا ومستقبلهم, بتهيئة البيئة الملائمة التي تعينهم على نثر إبداعاتهم في المستقبل والاحتكاك بمن سبقوهم من حيث الخبرة في نطاق الأعمال',
        'High sources always seek to give priority to the best staff in various fields, to build the present and the future of our partners, by creating the appropriate environment that will help them spread their creativity in the future and interact with those who preceded them in terms of experience in the field of business'
    ]

    const screenWidth  = () => {
        if(window.innerWidth <= 800){
            return true;
        }else{
            return false;
        }
    };

    return (
        <div className='section-2' id='الرؤية' style={{ flexWrap: screenWidth() ? 'wrap' : 'nowrap' }}>



            <div className='container-2-1' style={{ width: screenWidth() ? '100%' : '50%', padding: screenWidth() ? '4vw' : '4vw 8vw 4vw 1.5vw'}}>

                <h1 style={{width: english ? 'auto' : 'max-content', textAlign: english ? 'end' : 'start', alignSelf: screenWidth() ? 'center' : 'flex-end'}}>
                    {english ? title[3] : title[2]}
                </h1>

                <img src="./assets/images/message.jpg" alt="" style={{width: screenWidth() ? '100%' : '55%' }}/>
                
                <p style={{textAlign: english ? 'end' : 'justify', width: screenWidth() ? '100%' : '75%'}}>
                    {english ? text[3] : text[2]}
                </p>

            </div>



            <div className='container-2-2' style={{ width: screenWidth() ? '100%' : '50%', padding: screenWidth() ? '4vw' : '4vw 1.5vw 4vw 8vw'}}>
                
                <h1 style={{width: english ? 'auto' : 'max-content', textAlign: english ? 'end' : 'start', alignSelf: screenWidth() ? 'center' : 'flex-start'}}>
                    {english ? title[1] : title[0]}
                </h1>

                <img src="./assets/images/vision.jpg" alt="" style={{width: screenWidth() ? '100%' : '55%' }} />

                <p style={{textAlign: english ? 'end' : 'justify', width: screenWidth() ? '100%' : '75%'}}>
                    {english == true ? text[1] : text[0]}
                </p>
                
            </div>
        </div>
    );
}
 
export default VisionMessage;