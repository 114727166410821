import React from 'react';
import '../Styles/callToAction.css';

const CallToAction = (props) => {
    const english = props.english;

    const title = ['أفضل كادر من المصادر...', '...The Best Sources'];

    const elements = [
        {icon: './assets/icons/users.png', number: 3100, textAR: 'موظف', textEN: 'Employee'},
        {icon: './assets/icons/trophy.png', number: 2, textAR: 'جائزة', textEN: 'Awards won'},
        {icon: './assets/icons/flag.png', number: 9, textAR: 'وزارات خدمت', textEN: 'Served Ministries'},
        {icon: './assets/icons/file.png', number: 48, textAR: 'مشروع مكتمل', textEN: 'Projects completed'},
    ];
    
    const screenWidth  = () => {
        if(window.innerWidth <= 800){
            return true;
        }else{
            return false;
        }
    };

    return (
        <div className='section-7' id='s7'>

            <div className='container-7-1'>
                <img src="./assets/logo_icon.png" alt="" />
                <h1>{english == true ? title[1] : title[0]}</h1>
            </div>

            <div className='container-7-2' style={{flexWrap: screenWidth() ? 'wrap' : 'nowrap'}}>
                {elements.map((element) => (
                    <div className='container-7-3'>
                        <img src={element.icon} alt="" />
                        <h2>{element.number}</h2>
                        <p>{english == true ? element.textEN : element.textAR}</p>
                    </div>
                ))}
            </div>

        </div>
        
    );
}
 
export default CallToAction;