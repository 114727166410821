import React, { useState } from 'react';

import Home from './Components/Home';
import VisionMessage from './Components/VisionMessage';
import Story from './Components/Story';
import Reasons from './Components/Reasons';
import Goals from './Components/Goals';
import Services from './Components/Services';
import CallToAction from './Components/CallToAction';
import Customer from './Components/Customer';
import Footer from './Components/Footer';


import './Styles/home.css';
function App() {

  const [eng, setEng] = useState(false);
  const [hide, setHide] = useState(false);

  const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'}); 

  const navText = [
    ['الصفحة الرئيسية', 'Home'],
    ['رؤيتنا', 'Vision'],
    ['لماذا المصادر', 'Why Sources'],
    ['أهدافنا', 'Goals'],
    ['خدماتنا', 'Services'],
    ['التواصل', 'Contact us'],
    ['English', 'عربي'],
  ];

  const screenWidth  = () => {
    if(window.innerWidth <= 800){
        return true;
    }else{
        return false;
    }
  };

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
      height: "auto",
      position: 'relative'
    }}>
      <nav style={{
        position: 'fixed',
        top: '0',
        right: '0',
        zIndex: '99',
        backgroundColor: screenWidth() && hide ? '#000000f0' : '#000000d0',
        flexDirection: screenWidth() && hide ? 'column' : 'row',
        height: screenWidth() && hide ? '100vh' : 'auto'
      }}>
        <div className='container-1-1' style={{
          height: screenWidth() && hide ? '85%' : 'auto',
          width: screenWidth() && hide ? '100%' : 'auto',
          justifyContent: screenWidth() && hide ? 'space-evenly' : 'center',
          flexDirection: screenWidth() && hide ? 'column' : 'row',
          marginRight: screenWidth() ? '0' : 'auto'
        }}>
            <img src="./assets/icon.png" alt="" style={{  height: screenWidth() && hide ? 'calc(50px + 10vw)' : 'calc(25px + 2vw)', width: screenWidth() && hide ? 'calc(50px + 10vw)' : 'calc(25px + 2.2vw)',}} />
            
            {hide || !screenWidth() ?
            <>
              <a href="#الرئيسية" onClick={() => setHide(false)}><h4 id='home' style={{fontSize: screenWidth() && hide ? 'calc(20px + 0.15vw)' : 'calc(14px + 0.15vw)'}}>
                {eng ? navText[0][1] : navText[0][0]}
              </h4></a>
              <a href="#الرؤية" onClick={() => setHide(false)}><h4 id='vm' style={{fontSize: screenWidth() && hide ? 'calc(20px + 0.15vw)' : 'calc(14px + 0.15vw)'}}>
                {eng ? navText[1][1] : navText[1][0]}
              </h4></a>
              <a href="#المصادر" onClick={() => setHide(false)}><h4 id='reason' style={{fontSize: screenWidth() && hide ? 'calc(20px + 0.15vw)' : 'calc(14px + 0.15vw)'}}>
                {eng ? navText[2][1] : navText[2][0]}
              </h4></a>
              <a href="#الهدف" onClick={() => setHide(false)}><h4 id='goal' style={{fontSize: screenWidth() && hide ? 'calc(20px + 0.15vw)' : 'calc(14px + 0.15vw)'}}>
                {eng ? navText[3][1] : navText[3][0]}
              </h4></a>
              <a href="#الخدمة" onClick={() => setHide(false)}><h4 id='service' style={{fontSize: screenWidth() && hide ? 'calc(20px + 0.15vw)' : 'calc(14px + 0.15vw)'}}>
                {eng ? navText[4][1] : navText[4][0]}
              </h4></a>
            </> 
            : <>
                <button onClick={() => setHide(!hide)}>
                  <img src="./assets/icons/menu.svg" alt="" />
                </button>
            </>}

        </div>

        <div className='container-1-2'>
          <a href="#التواصل" onClick={() => setHide(false)}><button id='b1'>
            {eng && !screenWidth() ? navText[5][1] : !eng && !screenWidth() ? navText[5][0] : <img src="./assets/icons/headset.png" style={{height: '10vw', width: '10vw', display: 'flex'}} alt="" />}
          </button></a>
          <button id='b2' onClick={() => setEng(!eng)} style={{marginTop: screenWidth() ? '0' : 'auto'}}>
            {eng && !screenWidth() ? navText[6][1] : !eng && !screenWidth() ? navText[6][0] : <img src="./assets/icons/eta.png" style={{height: '10vw', width: '10vw', display: 'flex'}} alt="" />}
          </button>
        </div>
      </nav>

      <button onClick={() => {
        setHide(false);
        scrollToTop();
      }} style={{
        position: 'fixed',
        right: '1.5%',
        bottom: '1.5%',
        zIndex: '99',
        backgroundColor: '#ffffff',
        border: '0px',
        color: 'white',
        fontWeight: 'bold',
        padding: '5px',
        margin: '5px',
        cursor: 'pointer',
        height: '45px',
        width: '45px',
        borderRadius: '5px',
      }}>
        <img src="./assets/icons/up.png" alt="" />
      </button>

      <Home english={eng} />
      <VisionMessage english={eng} />
      <Story english={eng} />
      <Reasons english={eng} />
      <Goals english={eng} />
      <Services english={eng} />
      <CallToAction english={eng} />
      <Customer english={eng} />
      <Footer english={eng} />

      <p style={{
        background: "#2F2F2D",
        paddingBottom: '16px',
        color: '#f2f2f2',
        margin: '0',
        fontSize: 'calc(10px + 0.4vw)',
        padding: '0 6vw 1vw 6vw'
      }}>COPYRIGHT © 2019. ALL RIGHTS RESERVED TO SOURCES</p>

    </div>
  );
}

export default App;
