import React from 'react';
import '../Styles/story.css';

const Story = (props) => {

    const english = props.english;
    const title = ['قصتنا', 'Our Story'];

    const screenWidth  = () => {
        if(window.innerWidth <= 800){
            return true;
        }else{
            return false;
        }
    };


    const text = [
        'عقد كامل مضى على تأسيس المصادر, وما زالت متمسكة بأساسها القويم والمرتكز على تنويع مصادر الارتقاء بقطاع الأعمال السعودي واختيار أفضل الكوادر البشرية لعملائها, وهو السبب المباشر لتسمية الشركة ب(المصادر العالية), والآن مع رؤية المملكة 2030م, فإن الإصرار ازداد لدينا بشكل أكبر للاستمرار فيما مضينا فيه منذ البدايات, بمساعدة عملائنا عن البحث عن الكوادر اللازمة ليكونوا رواداً في شتى مجالاتهم العملية', 
        'A full decade has passed since the establishment of the sources, and it still adheres to its sound foundation based on diversifying the sources of upgrading the Saudi business sector and selecting the best human staff for its clients, which is the direct reason for calling the company (high resources), and now with the vision of the Kingdom 2030 AD, our insistence has increased even more to continue As we have gone through it since the beginning, with the help of our clients to search for the necessary staff to be pioneers in their various practical fields'
    ]

    return (
        <div className='section-3' id='القصة' style={{ flexDirection: screenWidth() == true ? 'column' : 'row' }}>

            <div className='container-3' style={{width: screenWidth() == true ? '100%' : '60%', alignItems: english && screenWidth() ? 'center': 'start'}}>

                <h1 style={{alignSelf: english ? 'flex-end' : 'flex-start'}}>{english == true ? title[1] : title[0]}</h1>

                <p style={{textAlign: english ? 'end' : 'justify'}}>
                    {english == true ? text[1] : text[0]}
                </p>
                <div></div>
            </div>

            <div className='img-comp' style={{width: screenWidth() ? '100%' : '40%'}}>
                <div className='sq1'></div>
                <div className='bd-sq1' style={{left: screenWidth() ? '-8%' : '-10%'}}></div>
                <img src="./assets/images/story.jpg" alt="" style={{width: screenWidth() == true ? '100%' : '-webkit-fill-available'}}/>
            </div>
            
        </div>
    );
}
 
export default Story;