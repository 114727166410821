import React from 'react';
import '../Styles/footer.css';

const Footer = (props) => {
    const english = props.english;

    const text = [
        ['من نحن', 'About us', 'الرسالة', 'Our Message', 'الرؤية', 'Our Vision', 'قصتنا', 'Our Story'],
        ['مميزاتنا', 'Our Features', 'لماذا المصادر','Why Sources', 'أهدافنا', 'Our Goals', 'خدماتنا', 'Our Services', 'عملاءنا', 'Our Clients'],
        ['طرق التواصل', 'Contact us'],
        ['الرياض - جدة', 'Riyadh - Jeddah', 'المملكة العربية السعودية', 'Saudi Arabia']
    ];

    const screenWidth  = () => {
        if(window.innerWidth <= 800){
            return true;
        }else{
            return false;
        }
    };

    return (
        <div className='section-9' id='التواصل'>

            <img className='logo-footer' src="./assets/logo.png" alt="" />

            <div>
                <h4>{english == true ? text[0][1] : text[0][0]}</h4>
                <ul>
                    <li><a href="#الرؤية">
                        {english == true ? text[0][3] : text[0][2]}
                    </a></li>

                    <li><a href="#الرؤية">
                        {english == true ? text[0][5] : text[0][4]}
                    </a></li>

                    <li><a href="#القصة">
                        {english == true ? text[0][7] : text[0][6]}
                    </a></li>
                </ul>
            </div>

            <div>
                <h4>{english == true ? text[1][1] : text[1][0]}</h4>
                <ul>
                    <li><a href="#المصادر">
                        {english == true ? text[1][3] : text[1][2]}
                    </a></li>

                    <li><a href="#الهدف">
                        {english == true ? text[1][5] : text[1][4]}
                    </a></li>

                    <li><a href="#الخدمة">
                        {english == true ? text[1][7] : text[1][6]}
                    </a></li>

                    <li><a href="#عملاءنا">
                        {english == true ? text[1][9] : text[1][8]}
                    </a></li>
                </ul>
            </div>

            <div>
                <h4>{english == true ? text[2][1] : text[2][0]}</h4>
                <ul>
                    <li>920009591</li>
                </ul>
            </div>

            <div>

                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', }}>
                    
                    <button>
                        <a href="mailto: omar41saad@outlook.com">
                            <img src="./assets/icons/email.svg" alt="" />
                        </a>
                    </button>
                    
                    <button onClick={() => window.open('https://www.youtube.com/channel/UCH8oZLaWpPAWOGbmEZ8H3kw', '_self')}>
                        <img src="./assets/icons/youtube.svg" alt="" />
                    </button>
                    <button onClick={() => window.open('https://www.instagram.com/sourcescompany/', '_self')}>
                        <img src="./assets/icons/instagram.svg" alt="" />
                    </button>
                    <button onClick={() => window.open('https://www.linkedin.com/company/highsourcescompany/about/', '_self')}>
                        <img src="./assets/icons/linkedin.svg" alt="" />
                    </button>
                    <button onClick={() => window.open('https://twitter.com/sourcescompany1', '_self')}>
                        <img style={{}} src="./assets/icons/twitter.svg" alt="" />
                    </button>
                </div>


                <h5>
                    {english == true ? text[3][1] : text[3][0]}
                    <br/>
                    {english == true ? text[3][3] : text[3][2]}
                </h5>
            </div>

        </div>
    );
}
 
export default Footer;