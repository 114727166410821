import React from 'react';
import '../Styles/services.css';

const Services = (props) => {

    const helps = [
        {icon: './assets/icons/start.svg', nameAR: 'التشغيل', text: '', nameEN: 'Operating'},
        {icon: './assets/icons/hospital.svg', nameAR: 'الخدمات الطبية المساندة', text: '', nameEN: 'Medical Support Services'},
        {icon: './assets/icons/bag.svg', nameAR: 'تأسيس الأعمال', text: '', nameEN: 'Establishing business'},

        {icon: './assets/icons/help.svg', nameAR: 'الخدمات المساندة', text: '', nameEN: 'Support Services'},
        {icon: './assets/icons/sales.svg', nameAR: 'إدارة المشتريات', text: '', nameEN: 'Purchase management'},
        {icon: './assets/icons/logs.svg', nameAR: 'الإمداد اللوجستي', text: '', nameEN: 'Logistical supply'},

        {icon: './assets/icons/files.svg', nameAR: 'الخدمات المكتبية والإدارية', text: '', nameEN: 'Office and administrative services'},
        {icon: './assets/icons/edit.svg', nameAR: 'إدارة المحتوى', text: '', nameEN: 'Content management'},
        {icon: './assets/icons/call.svg', nameAR: 'مراكز الاتصال وخدمة العملاء', text: '', nameEN: 'Call centers and customer service'},
    ];

    const english = props.english;
    const title = ['خدماتنا', 'Our Services'];

    const screenWidth  = () => {
        if(window.innerWidth <= 800){
            return true;
        }else{
            return false;
        }
    };

    return (
        <div className='section-6' id='الخدمة'>

            <h1 className='title-6'>{english == true ? title[1] : title[0]}</h1>

            <div className='container-6' style={{padding: screenWidth() ? 'calc(20px + 1.15vw) calc(5px + 1vw) 0' : 'calc(20px + 1.15vw) calc(5px + 9vw) 0', }}>
                {helps.map((service) => (

                    <button className='cd' style={{width: screenWidth() ? '40%' : 'calc(60px + 11.8vw)'}}>

                        <img src={service.icon} alt="" />
                        <div></div>
                        <h4 style={{fontSize: screenWidth() ? 'calc(10px + 0.5vw)' : 'calc(8px + 0.5vw)'}} >{english== true ? service.nameEN : service.nameAR}</h4>

                    </button>

                ))}
            </div>
        </div>
    );
}
 
export default Services;